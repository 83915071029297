const { bottom } = require("@popperjs/core");

require("gsap/dist/gsap.min.js");
require("gsap/dist/ScrollTrigger.min.js");
// gsap.registerPlugin(ScrollTrigger);



// const $navbarHeight = $(".sahanabar").outerHeight();
// ScrollTrigger.create({
//   trigger: ".custom_shop-fixedParent",
//   pin: ".custom_shop-fixedParent .col-sm-2 ",
// //   markers: true,
//   onEnter: () => {
//     // gsap.set(".fixed_child", { top: `${$navbarHeight}px` });
//     console.log("log from onEnter", $navbarHeight);
//   },
//   onEnterBack: () => {
//     console.log("log from onEnterBack", $navbarHeight);
//   },
//   scrub: true,
//   end: "bottom top",
// });
// console.log("log from sscustom");


// const tl = gsap.timeline({
//     scrollTrigger: {
//       trigger: ".trig",
//       start: "center bottom",
//       end: "center top",
//       scrub: true,
//       markers: true,
//       onEnter: () => $(this).removeClass('overlay-id'),
//       onLeave: () => $(this).addClass('overlay-id'),
//       onEnterBack: () => $(this).addClass('overlay-id'),
//       onLeaveBack: () => $(this).removeClass('overlay-id'),
//     }
//   });


// gsap.registerPlugin(ScrollTrigger);
// gsap.to(".custom_shop-fixedParent", {
//     scrollTrigger: {
//   trigger: ".custom_shop-fixedParent .col-sm-2",
//     toggleActions: "restart none none reverse",
//     id: "pin",
//   markers:true,
//       pin: true,
//       end:"bottom top"
//     },
// });