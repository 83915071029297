// Navbar product accordion
// var mobileLinkOpen=false;
// var burgerOpen = false;

require("jquery-zoom/jquery.zoom.min.js");
$(".sahanabar__mobile-link-trigger").on("click", function (e) {
  e.preventDefault();
  $(this)
    .next(".sahanabar__mobile-dropdown-panel")
    .not(":animated")
    .slideToggle(200, "linear");
  // mobileLinkOpen = !mobileLinkOpen;
  // console.log(mobileLinkOpen);
});

// Our team
$(".trigger").on("click", function (e) {
  e.preventDefault();
  $(this)
    .next(".panel")
    .not(":animated")
    .slideToggle(200, "linear");
});

// Guitar accordion

$(".sahanabar__mobile--dropdown-link-trigger").on("click", function (e) {
  e.preventDefault();
  $(this)
    .next(".sahanabar__mobile--dropdown-link-panel")
    .not(":animated")
    .slideToggle(200, "linear");
});
//ankit
$(".sahanabar__mobile--dropdown-link-trigger-ank").on("click", function (e) {
  console.log($(this).next());
  e.preventDefault();
  $(this)
    .next(".sahanabar__mobile--dropdown-link-panel-ank")
    .not(":animated")
    .slideToggle(200, "linear");
});
//ankit

// Mobile navbar accordion starts

$(".sahanabar__mobile-control").on("click", ".burger__menu", function (e) {
  e.preventDefault();
  $(".sahanabar__mobile").toggleClass("sahanabar__mobile--bgWhite");
  $(".sahanabar__mobile-control")
    .next(".sahanabar__mobile-panel")
    .not(":animated")
    .slideToggle(200, "linear");
  // burgerOpen = !burgerOpen;
  // console.log(burgerOpen);
  // if(burgerOpen & mobileLinkOpen){

  // }
});

// User dashboard JS Starts

$(".sahanabar--user").on("click", function (e) {
  e.preventDefault();
  $(".sahanabar--right-menu")
    .removeClass("sahanabar--right-menu-invisible")
    .addClass("sahanabar--right-menu-visible");
});

$(document).on("click", "body", function () {
  $(".sahanabar--right-menu")
    .removeClass("sahanabar--right-menu-visible")
    .addClass("sahanabar--right-menu-invisible");
});

$(document).on("click", ".sahanabar--user", function (event) {
  event.stopImmediatePropagation();
});

// User dashboard JS Ends

// Mobile User dashboard JS starts

$(".sahanabar__mobile--user").on("click", function (e) {
  e.preventDefault();
  $(".sahanabar__mobile--right-menu")
    .removeClass("sahanabar__mobile--right-menu-invisible")
    .addClass("sahanabar__mobile--right-menu-visible");
});

$(document).on("click", "body", function () {
  $(".sahanabar__mobile--right-menu")
    .removeClass("sahanabar__mobile--right-menu-visible")
    .addClass("sahanabar__mobile--right-menu-invisible");
});

$(document).on("click", ".sahanabar__mobile--user", function (event) {
  event.stopImmediatePropagation();
});

// Mobile User dashboard JS ends

// Mobile navbar accordion ends

$(document).on("click", ".different-billing-trigger", function (e) {
  $(".different-billing-panel")
    .not(":animated")
    .slideToggle(200, "linear");
});

$(document).on("click", ".different-shipping-trigger", function (e) {
  $(".different-shipping-panel")
    .not(":animated")
    .slideToggle(200, "linear");
});
// Cart accordion starts

// $(document).on('click', '.sahana-account span', function(e){
// 	$('.sahana-account-panel').not(':animated').slideToggle(200,'linear');
// });

// Cart accordion ends
$(".sahanabar__mobile--left--logo").on("click", function () {
  $(this).animate({ display: "none" });
});

// Different panel starts

// $(document).on('click', '.different-accordion-trigger', function(e){
// 	$('.different-accordion-panel').not(':animated').slideToggle(200,'linear');
// });

// Hamburger animation JS

let menuState = open;
$(".burger__menu").on("click", function () {
  if (menuState == "open") {
    $(this).removeClass("open");
    menuState = "close";
  } else {
    $(this).addClass("open");
    menuState = "open";
  }
});

// Hamburger animation JS

// Count guitar quantity
//
// $('.guitar__grid--qty--inc').on('click', function(){
// 	var guitarCount = $('.guitar__grid--qty').text();
// 	guitarCount ++;
// 	$('.guitar__grid--number').text(guitarCount);
// 	// console.log(guitarCount.type());
// 	// console.log(guitarCount);
// });

$(".guitar__grid--qty--inc").on("click", function () {
  var guitarCount = parseInt($("#quantityP").val());
  var maxCount = parseInt($("#maxQ").val());
  if (guitarCount < maxCount) {
    guitarCount++;
  }
  $("#quantityP").val(guitarCount);
  // console.log(guitarCount.type());
  // console.log(guitarCount);
});

$(".guitar__grid--qty--dec").on("click", function () {
  var guitarCount = parseInt($("#quantityP").val());
  if (guitarCount == 1) {
    $("#quantityP").val(guitarCount);
  } else {
    guitarCount--;
    $("#quantityP").val(guitarCount);
    // console.log(guitarCount);
  }
  // console.log(guitarCount.type());
});

// Count guitar quantity

// Image gallery JS in product page

var request; //Latest image to be requested
var $current; //Image currently being shown
var cache = {}; //cache object
var $frame = $("#photo-viewer"); //container for image
var $thumbs = $(".thumb"); //container for image

function crossfade($img) {
  //Function to fade between images

  if ($current) {
    //Pass in new image as paramenter
    $current.stop().fadeOut("slow"); //If there is currently an image showing stop animation and fade it out
  }

  //$img.css({	//set the CSS margins for the image
  //marginLeft: -$img.width()/2,	//Negative margin of half image's width
  //marginRight: -$img.height()/2	//Negative margin of half image's height
  //});

  $img.stop().fadeTo("slow", 1); //Stop animation on new image and fade in

  $current = $img; //New image becomes current image
  console.log($current[0].src);

  // Check to see if width of the window is less than 768px
  if (window.innerWidth < 768) {
    $("#photo-viewer").trigger("zoom.destroy");
  } else {
    $("#photo-viewer").zoom({
      url: $current[0].src,
      magnify: 1.5,
      touch: true,
    });
  }
}

$(document).on("click", ".thumb", function (e) {
  //when a thumb is clicked on
  var $img; //Create local variable called $img
  var src = this.href; //Store path to image
  request = src;

  e.preventDefault(); //stop the default link behaviour

  $thumbs.removeClass("active"); //Remove active class from all thumbs
  $(this).addClass("active"); //Add active to clicked thumb

  if (cache.hasOwnProperty(src)) {
    //If cache contains this image
    if (cache[src].isLoading === false) {
      //And if isLoading is false
      crossfade(cache[src].$img); //call crossfade function
    }
  } else {
    //otherwise it is not in cache
    $img = $("<img/>"); //store empty <img/> in $img
    cache[src] = {
      //store this image in cache
      $img: $img, //add the path to the image
      isLoading: true, //set isLoading property to true
    };
  }

  //Next few lines will run when image has loaded but are prepared first
  $img.on("load", function () {
    //When image has loaded
    //$img.hide();	//Hide it
    //Remove is-loading class from frame and append new image to it
    $frame.removeClass("is-loading").append($img);
    cache[src].isLoading = false; //Upload isLoading in cache
    //If still most recently requested image then
    if (request === src) {
      crossfade($img); //Calls crossfade function, solves asynchronous loading issue
    }
    // console.log(cache.src.$img);
  });

  $frame.addClass("is-loading"); //Add is-loading class to frame

  $img.attr({
    //Set attributes on <img> element
    src: src, //Add src attribute to load image
    alt: this.title || "", //Add title if one was give in link
  });
  // console.log(Object.keys(cache)[0]);
  // console.log(cache[src].$img[0].src);
  // $('#photo-viewer').zoom({url: cache[src].$img[0].src});
});

// Last line runs once (when rest of script has loaded) to show the first image
$(".thumb")
  .eq(0)
  .click(); //Simulate click on first thumbnail
// Disable jquery zoom on mobile screens
// Create a condition that targets viewports at least 768px wide
const zoomBreakpoint = 768;
const mediaQuery = window.matchMedia(`(max-width: ${zoomBreakpoint}px)`);

function handleTabletChange(e) {
  // Check if the media query is true
  if (e.matches) {
    // Then log the following message to the console
    console.log(`Screen size less then ${zoomBreakpoint}px`);
    $("#photo-viewer").trigger("zoom.destroy");
  } else {
    console.log(`Screen size greater then ${zoomBreakpoint}px`);
    $("#photo-viewer").zoom();
  }
}

// Register event listener
mediaQuery.addListener(handleTabletChange);

// Initial check
handleTabletChange(mediaQuery);

// Image gallery JS in product page

$(".heading-secondary").on("click", function () {
  $(".testing").val(123);
});

// Product Listing page JS

$(".product__list").on("mouseover", function () {
  $(this).addClass("product__list-hovered");
});

// Smooth show hide sticky navbar




// Smooth show hide sticky navbar

// var lastScrollTopMobile = 0;
// $(window).scroll(function(event){
// 	var st = $(this).scrollTop();
// 	var mobileNavHeight = $('.sahanabar__mobile').height();
// 	if (st > lastScrollTopMobile){
// 	   // downscroll code
// 	   $('.sahanabar__mobile.sticky-mobile').css('top', -80);
// 	} else {
// 	  // upscroll code
// 	  $('.sahanabar__mobile.sticky-sahana').css('top', 0);
// 	}
// 	lastScrollTopMobile = st;
// });

$(window).bind("scroll", function (e) {
  var navHeight = $(".sahanabar").height();
  // var mobileNavHeight = $('.sahanabar__mobile').height();
  if ($(window).scrollTop() > navHeight) {
    $(".sahanabar").addClass("sticky-sahana");
    // $('.sahanabar__mobile').addClass('sticky-mobile');
    $(".footers__scrollTop").addClass("footers__scrollTop-visible");
  } else {
    $(".sahanabar").removeClass("sticky-sahana");
    $(".footers__scrollTop").removeClass("footers__scrollTop-visible");
    // $('.sahanabar__mobile').removeClass('sticky-mobile');
  }
});

// $(window).bind("scroll", function(e) {
//   // var navHeight = $('.sahanabar').height();
//   var mobileNavHeight = $(".sahanabar__mobile").height();
//   if ($(window).scrollTop() > mobileNavHeight) {
//     // $('.sahanabar').addClass('sticky-sahana');
//     $(".sahanabar__mobile").addClass("sticky-mobile");
//   } else {
//     // $('.sahanabar').removeClass('sticky-sahana');
//     $(".sahanabar__mobile").removeClass("sticky-mobile");
//   }
// });

// $(document).ready(function(){
//   $('#photo-viewer').zoom();
// });

//sticky navbar js
// $(window).bind('scroll', function(e){
// 	var navHeight = $('.sahanabar').height();
// 	// var mobileNavHeight = $('.sahanabar__mobile').height();
// 	if($(window).scrollTop() > navHeight || $(window).scrollTop() > 80){
// 		$('.sahanabar').addClass('sticky-sahana');
// 		$('.sahanabar__mobile').addClass('sticky-mobile');
// 	}
// 	else {
// 		$('.sahanabar').removeClass('sticky-sahana');
// 		$('.sahanabar__mobile').removeClass('sticky-mobile');
// 	}
// });


// Smooth Scroll to top

var artists = {
  joe: { guitar: "ibanez", amp: "marshall" },
  steve: { guitar: "cort", amp: "orange" },
};

var arr = [];
var navBig = $(".sahanabar");
arr.push(navBig);

// var $new = $('<li>hello</li>');
window.addEventListener("resize", function () {
  var $bigNavbar = $(".sahanabar");
  var homepageHeader = $(".homepage__header");
  if ($(window).width() < 900) {
    var detached = $bigNavbar.detach();
  } else {
    // detached.prependTo('header');
    arr[0].prependTo("header");
    // console.log(arr[0]);
  }
});
$(document).ready(function () {
  $(".nav-tabs a").click(function () {
    $(this).tab("show");
  });
});

$(window).on("load", function () {
  $(".status").fadeOut();
  $(".preloader")
    .delay(50)
    .fadeOut("slow");
});
// Tab panels
$(".tab-list").each(function () {
  // Find lists of tabs
  var $this = $(this); // Store this list
  var $tab = $this.find("li.active"); // Get the active list item
  var $link = $tab.find("a"); // Get link from active tab
  var $panel = $($link.attr("href")); // Get active panel

  $this.on("click", ".tab-control", function (e) {
    // When click on a tab
    e.preventDefault(); // Prevent link behavior
    var $link = $(this), // Store the current link
      id = this.hash; // Get href of clicked tab

    if (id && !$link.is(".active")) {
      // If not currently active
      $panel.removeClass("active"); // Make panel inactive
      $tab.removeClass("active"); // Make tab inactive

      $panel = $(id).addClass("active"); // Make new panel active
      $tab = $link.parent().addClass("active"); // Make new tab active
    }
  });
});
// Navbar product accordion
// var mobileLinkOpen=false;
// var burgerOpen = false;

// Create a condition that targets viewports at least 768px wide
// const mediaQuery = window.matchMedia("(max-width: 768px)");

// function handleTabletChange(e) {
//   // Check if the media query is true
//   if (e.matches) {
//     // Then log the following message to the console
//     console.log("Media Query Matched!");
//     $("#photo-viewer").trigger("zoom.destroy");
//   }
// }

// // Register event listener
// mediaQuery.addListener(handleTabletChange);

// // Initial check
// handleTabletChange(mediaQuery);

$(document).ready(function () {
  $(".toggle-icon").click(function () {
    $(".redirect-id").toggleClass("redirect-id-hidden");
    $(".to-icon").toggleClass("to-icon-full");
  });
});

// side-icon clear starts


const overlay = document.querySelector(".overlay");
const overlayid = document.querySelector(".overlay-id");
const endcler = document.querySelector(".end-cler");
// const endstart = document.querySelector(".end-start");
// const toggleicon = document.querySelector(".toogle-icon");
// const redirect=document.querySelector(".re")
const toggleicon = document.querySelector(".toggle-icon");

window.addEventListener("scroll", function () {

  var bodyRect = document.body.getBoundingClientRect(),
    endRect = endcler.getBoundingClientRect(),
    // endstarts = endstart.getBoundingClientRect(),
    offset = endRect.top - bodyRect.top;
  // offset2   = endstarts.top - bodyRect.top;
  // alert('Element is ' + offset + ' vertical pixels from <body>');
  //  console.log(offset);

  if (window.scrollY > offset) {
    overlay.classList.add("overlay-id");
    // console.log("hello");
  }
  else {
    overlay.classList.remove("overlay-id");
  }
});
// side-icon clear ends


// var bodyRect = document.body.getBoundingClientRect(),
//     endRect = endcler.getBoundingClientRect(),
//     offset   = endRect.top - bodyRect.top;

// alert('Element is ' + offset + ' vertical pixels from <body>');


// var crollTop = 0;
// $(window).scroll(function(event) {
//   var st = $(this).scrollTop();
//   // console.log(st);
//   var navHeight = $(".toggle-icon").height();
//   var iconHeight = $(".sahanabar__mobile").height();
//   if (st > crollTop) {
//     // downscroll code
//     $(".sahanabar.sticky-sahana").css("top", -(navHeight + 1));
//   } else {
//     // upscroll code
//     $(".sahanabar.sticky-sahana").css("top", 0);
//   }
//   lastScrollTop = st;
// });


// jQuery(function(){
//   jQuery('#led').click();
// });
// var mq = window.matchMedia( "(max-width: 968px)" );
//   mq.addListener(function(){
//     if (mq.matches) {
// jQuery(function(){
//   jQuery('#led').click();
// });
// }
// });












